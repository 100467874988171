import "../css/app.css";
import Alpine from "alpinejs";
import { animate, inView, stagger, scroll } from "motion";
// import './cookie3.js'

// ALPINE
window.Alpine = Alpine;
Alpine.start();

const claim = document.getElementById("m-claim");
const square = document.getElementById("m-square");
// const logo = document.getElementById('m-logo')
const social = document.getElementById("m-social");

scroll(
  animate(social, {
    opacity: ["100%", "0%"],
    transform: ["translateX(0)", "translateX(100px)"],
  }),
  { offset: ["1000px", "1500px"] }
);

if (claim) {
  scroll(
    animate(claim, {
      opacity: ["100%", "0%"],
    }),
    { offset: ["200px", "700px"] }
  );
}
if (square) {
  scroll(
    animate(square, {
      rotate: ["-45deg", "45deg"],
      opacity: ["100%", "0%"],
    }),
    { offset: ["200px", "700px"] }
  );
}

function animateSection(targetSelector, elementSelectors) {
  function getMargin() {
    if (window.matchMedia("(max-width: 767px)").matches) {
      return "0px 0px -150px 0px"; // Adjust for mobile view
    }
    return "0px 0px -300px 0px"; // Default for larger screens
  }

  inView(
    targetSelector,
    ({ target }) => {
      animate(
        target.querySelectorAll(elementSelectors),
        { opacity: 1, transform: "translate(0, 0)" },
        { delay: stagger(0.2), duration: 0.3, easing: [0.17, 0.55, 0.55, 1] }
      );
    },
    { margin: getMargin() }
  );
}

animateSection(
  "header.blocks--megahero",
  ".m-hero-1, .m-hero-2, .m-hero-3, .m-hero-4, .m-hero-5"
);
animateSection("section.blocks--header", ".m-h1");
animateSection(
  "section.blocks--text",
  ".m-h2, .m-text, .m-accordion, .m-marginal"
);
animateSection("section.blocks--cards", ".m-card");
animateSection("section.blocks--megacards", ".m-megacard");
animateSection("section.blocks--stats", ".m-stat");
animateSection("section.blocks--media", ".m-media");
animateSection("section.blocks--mediafull", ".m-media");
